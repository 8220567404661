import React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../../breakpoints";
import { parentVariants } from "../../../framerOptions";
import { motion } from "framer-motion";
import EventCard from "../EventCard";
import { Col, Row } from "rsuite";
import cartagenaImage from "./assets/images/cartagena.jpg";
import colchaguaImage from "./assets/images/colchagua.jpg";
import puntaCanaImage from "./assets/images/punta-cana.jpg";
import puyehyeImage from "./assets/images/puyehue.jpg";
import panamaImage from "./assets/images/panama.jpg";
import chileImage from "./assets/images/chile.jpg";
import patagoniaImage from "./assets/images/patagonia.jpg";
import vinaImage from "./assets/images/vina.jpg";
import { BsWhatsapp } from "react-icons/bs";

export default function Events() {
  return (
    <Container>
      <Layout.Container maxWidth={650}>
        <Title>Selecciona la experiencia que quieres conocer</Title>
      </Layout.Container>
      <Layout.Container>
        <motion.div
          variants={parentVariants}
          initial="hidden"
          animate="visible"
        >
          <Row
            gutter={32}
            style={{
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              alignItems: 'stretch',
            }}
          >
            {/* <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Panamá Business Veterinary"
                date="12, 13 y 14 marzo 2024"
                image={panamaImage}
                url="https://trazoevents.com/landing/veterinario-panama-2024/program"
              />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Puyehue Business Veterinary"
                date="24, 25 y 26 de abril 2024"
                image={puyehyeImage}
                url="https://trazoevents.com/landing/veterinario-puyehue-2024/program"
              />
            </Col> */}

            {/* <Col xs={24} sm={24} md={8}>
              <EventCard
                name="LATAM LAVECCS CHILE 2024 | VIÑA DEL MAR"
                date="04, 05 y 06 de junio 2024"
                image={vinaImage}
                url="https://trazoevents.com/landing/veterinario-vinadelmar-2024/program"
              />
            </Col> */}

            <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Congreso Veterinario de Chile"
                date="07, 08, 09 de agosto 2024"
                image={chileImage}
                // video="videos/congreso_chile.mp4"
                url="https://trazoevents.com/landing/veterinario-chile-2024/program"
              />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Punta Cana Business Veterinary"
                date="20, 21 y 22 agosto 2024"
                image={puntaCanaImage}
                // video="videos/punta.mp4"
                url="https://trazoevents.com/landing/veterinario-puntacana-2024/program"
              />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Colchagua Business Veterinary"
                date="09, 10 y 11 de octubre 2024"
                image={colchaguaImage}
                // video="videos/colchagua.mp4"
                url="https://trazoevents.com/landing/veterinario-colchagua-2024/program"
              />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Cartagena Business Veterinary"
                date="23, 24 y 25 de octubre 2024"
                image={cartagenaImage}
                // video="videos/cartagena.mp4"
                url="https://trazoevents.com/landing/veterinario-cartagena-2024/program"
              />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <EventCard
                name="Patagonia Veterinary Experience"
                date="17, 18, 19 y 20 diciembre 2024"
                image={patagoniaImage}
                // video="videos/punta.mp4"
                url="https://trazoevents.com/landing/veterinario-patagonia-2024/program"
              />
            </Col>
          </Row>
        </motion.div>
      </Layout.Container>
      <a
        href="https://wa.me/56996384517"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className="whatsapp-icon" />
      </a>
    </Container>
  );
}

const Container = styled.div`
  background: #f2f5f8;
  padding: 5.8rem 0 10rem;
`;

const Title = styled.h3`
  color: #393939;
  font-weight: 800;
  font-size: 3.7rem;
  line-height: inherit;
  text-align: center;
  margin-bottom: 5.8rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 2.7rem;
  }

  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    font-size: 2rem;
  }
`;
